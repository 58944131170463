jQuery(document).ready(function ($) {
  $("#cmn-toggle-4").on("change", () => {
    var url = location.href
    if ($("#cmn-toggle-4").is(":checked")) {
      location.href = url.replace(/\?.+/,'');
    } else {
      location.href = `${url}?displayed=display`;
    }
  });
  $(".js-favorite-hide-btn").on("click", (e) => {
    alert(I18n.t('js.favorite.alert_hidden'));
    e.preventDefault();
  });
});
