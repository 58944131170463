$(function() {
  $('.site-notification .notification-title').on('click', function(){
    var notificationId = $(this).attr('id');
    var modalBody = $(`#notification-detail-${notificationId}`).children().clone();
    $('#modalContent .modal-title').text($(this).text());
    $('#modalContent .modal-content').html(modalBody);
    $('#modalContent a.notification-close').attr('href', `/mypage/notifications/${notificationId}`);
    $('#modalContent.modal-default, #modalBackdrop.backdrop-default').fadeIn()
  });

  $('#modalContent .modal-close').on('click', function(){
    $('#modalContent.modal-default, #modalBackdrop.backdrop-default').fadeOut()
  })
});
