import YubinBango from './yubinbango'
(function ($) {
  "use strict";
  var originalElem = [];

  /**
   * jQuery Selector エスケープ処理
   * @param {string} str エスケープ対象文字列
   */
  var escapeSelectorString = function(str){
    return str.replace(/[ !"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, "\\$&");
  }

  /**
   * 選択内容のフィルタリング処理
   * @param {jQueryElement} $elem 選択内容のフィルタリングするエレメント
   * @param {jQueryElement} $trigger トリガーエレメント(null時、$elemの属性から取得)
   */
  var doFilter = function($elem, $trigger = null) {
    if (!originalElem[$elem.attr('id')]) {
      // 初期処理完了前
      return;
    }

    if ($trigger === null) {
      var by = escapeSelectorString($elem.attr('data-jp-location-filter-by'));
      var $by = $('[name="' + by + '"]');
      $trigger = $by;
    }
    var selected = $trigger.val();

    var $org = originalElem[$elem.attr('id')];
    var selector = ':not([data-pref-id])';
    if (selected !== '_none') {
      selector +=  ',[data-pref-id="' + selected + '"]';
    }
    var $options = $org.find(selector);

    $elem.empty();
    $options.each(function(e) {
      $elem.append($(this).clone());
    });
    $elem.val('_none');

    if ($elem.attr('data-jp-location-trigger') != undefined) {
      refreshRelated($elem);
    }
  };

  /**
   * 連携する選択リストを更新する処理
   * @param {jQueryElement} $trigger トリガーエレメント
   */
  var refreshRelated = function($trigger) {
    var $elem = $('[data-jp-location-filter-by="' + escapeSelectorString($trigger.attr('name')) + '"]');
    if ($elem.length > 0) {
      doFilter($elem, $trigger);
    }
  }

  /**
   * 入力値変更イベント処理
   * @param {object} event
   */
  var onChange = function(event) {
    refreshRelated($(event.target));
  };

  /**
   * 初期化処理
   */
  var init = function() {
    $('[data-jp-location-filter-by]').each(function(){
      var $select = $(this);
      var val = $select.val();
      originalElem[$select.attr('id')] = $select.clone();
      doFilter($select);
      $select.val(val);
    });

    $(document).on('change', '[data-jp-location-trigger]', onChange);

    // YubinbangoEx
    if (typeof YubinBango !== 'undefined') {
      YubinBango.MicroformatDom.prototype.onSetBefore.push(function(className, target, data) {
        // .p-regionを処理する場合
        if (className === 'p-region-id') {
          var $form = $(target);
          var $area = $form.find('.p-area-id');
          if ($area.length > 0) {
            var $pref = $('[data-jp-location-filter-by="' + escapeSelectorString($area.attr('name')) + '"]');

            var $org = originalElem[$pref.attr('id')];

            var areaId = '_none';
            var prefId = YubinBango.MicroformatDom.prototype.getData(data, 'region-id');
            var $opt = $org.find('[value="' + prefId + '"]');
            if ($opt.length > 0) {
              areaId = $opt.attr('data-pref-id');
            }

            $area.val(areaId);

            $area.each(function(){
              refreshRelated($(this));
            });
          }
        }
      });


      YubinBango.MicroformatDom.prototype.onSetAfter.push(function(className, target, data) {
        // .p-regionを処理する場合
        if (className === 'p-region') {
          var $form = $(target);
          var $pref = $form.find('.p-region-nmsel');
          if ($pref.find('option:selected').length === 0) {
            $pref.val('_none');
          }
          // 下位をリフレッシュ
          $pref.each(function(){
            refreshRelated($(this));
          });
          // 値セット用に現在値をクリア
          $form.find('.p-locality-nmsel').val('');
        } else if (className === 'p-locality') {
          var $form = $(target);
          var $locality = $form.find('.p-locality-nmsel');
          if ($locality.find('option:selected').length === 0) {
            $locality.val('_none');
          }
        }
      });
    }
  };

  /**
   * 画面ロード時処理
   */
  $(document).ready(function(){
    init();
  });

})(jQuery);
