$(document).ready(function() {
  checkShowEndedAt();

  $("#career_form_show_ended_at").on('change', function (e) {
    checkShowEndedAt();
  });

  function checkShowEndedAt() {
    if ($('#career_form_show_ended_at').is(":checked") || $('#show_ended_at').is(":checked")) {
      $('.js-ended-at').show();
    } else {
      $('.js-ended-at').hide();
    }
  };
});
