import YubinBango from './yubinbango'
(function() {
  'use strict';

  YubinBango.MicroformatDom.prototype.orgD = YubinBango.MicroformatDom.prototype.D;

  YubinBango.MicroformatDom.prototype.onSetBefore = [];
  YubinBango.MicroformatDom.prototype.onSetAfter = [];

  // utilities
  YubinBango.MicroformatDom.prototype.getData = function(data, prop) {
    switch(prop) {
      case 'region':
        return data.region;
      case 'region-id':
        return data.k;
      case 'locality':
        return data.l;
      case 'street':
      return data.m;
      case 'extended':
      return data.o;
    }
  };

  var setValueByLabel = function(select, label) {
    select.forEach(function(tgElem) {
      var val = null;
      var opt = tgElem.querySelectorAll('option');
      opt.forEach(function(optElem) {
        if (optElem.text === label) {
          val = optElem.value;
        }
      });
      tgElem.value = val;
    });
  };

  // function(t, n, e) {
  YubinBango.MicroformatDom.prototype.D = function(className, target, data) {

    YubinBango.MicroformatDom.prototype.onSetBefore.forEach(function(elem) {
      elem(className, target, data);
    });

    // オリジナルの処理をコール
    YubinBango.MicroformatDom.prototype.orgD(className, target, data);

    // .p-regionを処理する場合
    if (className === 'p-region') {
      var region = data.region;
      var obj = target.querySelectorAll('.p-region-nmsel');
      setValueByLabel(obj, region);
    } else if (className === 'p-locality') {
      var obj = target.querySelectorAll('.p-locality-nmsel');
      var locality = YubinBango.MicroformatDom.prototype.getData(data, 'locality');
      setValueByLabel(obj, locality);
    }

    YubinBango.MicroformatDom.prototype.onSetAfter.forEach(function(elem) {
      elem(className, target, data);
    });

  }
}());
