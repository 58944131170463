import I18n from "i18n-js"

export const SETTINGS = {
  "basePath": "/",
  "pathPrefix": "",
  "ajaxPageState": {
    "theme": "jinzai_draft",
  },
  password: {
    strengthTitle: I18n.t('js.password.strengthTitle'),
    hasWeaknesses: I18n.t('js.password.hasWeaknesses'),
    tooShort: I18n.t('js.password.tooShort'),
    addLowerCase: I18n.t('js.password.addLowerCase'),
    addUpperCase: I18n.t('js.password.addUpperCase'),
    addNumbers: I18n.t('js.password.addNumbers'),
    addPunctuation: I18n.t('js.password.addPunctuation'),
    sameAsUsername: I18n.t('js.password.sameAsUsername'),
    confirmSuccess: I18n.t('js.password.confirmSuccess'),
    confirmFailure: I18n.t('js.password.confirmFailure'),
    weak: I18n.t('js.password.weak'),
    fair: I18n.t('js.password.fair'),
    good: I18n.t('js.password.good'),
    strong: I18n.t('js.password.strong'),
    confirmTitle: "パスワードの一致：",
    username: I18n.t('js.password.username')
  },
  views_save: {
    "modal_title":"検索条件保存",
    "modal_settings":{
       "modalSize":{
          "type":"fixed",
          "width":250,
          "height":170
       },
       "modalOptions":{
          "opacity":0.85,
          "background":"#000"
       },
       "animation":"fadeIn",
       "modalTheme":"ViewsSavePopup",
       "closeText":"閉じる"
    },
    "modal_theme":`<div id="ctools-modal" class="popups-box">
      <div class="ctools-modal-content views-save-modal-content">
        <div class="popups-container">
          <div class="modal-header popups-title">
            <span id="modal-title" class="modal-title"></span>
            <span class="popups-close close">閉じる</span>
            <div class="clear-block"></div>
          </div>
          <div class="modal-scroll"><div id="modal-content" class="modal-content popups-body"></div></div>
        </div>
      </div>
    </div>`
 },
  back_to_top: {
    back_to_top_button_trigger: 100,
    back_to_top_button_text: '',
    '#attached ': {
      library: [
        [
          'system',
          'ui'
        ]
      ]
    }
  },
  responsive_menus: [
    {
      selectors: "#block-menu-menu-menuusermenuloginsp,#block-menu-menu-logoutsp",
      container: "header#masthead",
      trigger_txt: false,
      close_txt: "X",
      close_size: "16px",
      position: "right",
      media_size: "767",
      show_children: true,
      expand_children: true,
      expand_txt: "+",
      contract_txt: "-",
      remove_attrs: true,
      responsive_menus_style: "mean_menu"
    }
  ],
  faq:{
    faq_hide_qa_accordion: 0,
    faq_category_hide_qa_accordion: false
  },
  "entityreference_filter": {
    "views-exposed-form-views-jzd-job-search-page-job-search": {
      "view": "views_jzd_job_search/page_job_search",
      "args": [],
      "dynamic": {
        "field_office_location_prefecture_target_id": [
          "field_office_location_area_target_id_entityreference_filter",
          "field_office_location_area_target_id_entityreference_filter"
        ],
        "field_office_location_city_target_id_entityreference_filter": [
          "field_office_location_prefecture_target_id",
          "field_office_location_prefecture_target_id"
        ]
      }
    }
  },
  "better_exposed_filters": {
    "datepicker": false,
    "slider": false,
    "settings": [],
    "autosubmit": true,
    "views": {
      "views_jzd_job_search": {
        "displays": {
          "page_job_by_office": {
            "filters": {
              "field_job_point_search_tid": {
                "required": false
              },
              "field_pickup_flg_tid": {
                "required": false
              },
              "field_employ_status_value": {
                "required": false
              },
              "field_job_license_list_tid_subquery": {
                "required": false
              }
            }
          },
          "page_job_search": {
            "filters": {
              "field_office_location_area_target_id_entityreference_filter": {
                "required": false
              },
              "field_office_location_prefecture_target_id": {
                "required": false
              },
              "field_office_location_city_target_id_entityreference_filter": {
                "required": false
              },
              "field_employ_status_value": {
                "required": false
              },
              "field_job_license_list_tid_subquery": {
                "required": false
              },
              "field_company_industry_tid": {
                "required": false
              },
              "company_name_text": {
                "required": false
              },
              "field_office_type_value_1": {
                "required": false
              },
              "field_job_point_search_tid": {
                "required": false
              },
              "field_pickup_flg_tid": {
                "required": false
              }
            }
          }
        }
      },
      "prjobsearch": {
        "displays": {
          "prpage": {
            "filters": {
              "field_office_location_prefecture_target_id": {
                "required": false
              },
              "field_employ_status_value": {
                "required": false
              }
            }
          }
        }
      },
      "recently_read": {
        "displays": {
          "block_1": {
            "filters": []
          }
        }
      },
      "jobtaxomy": {
        "displays": {
          "block_2": {
            "filters": []
          }
        }
      }
    }
  },
  "form_placeholder": {
    "fallback_support": false,
    "include": "#user-register-form *",
    "exclude": "",
    "required_indicator": "leave"
  },
  "urlIsAjaxTrusted": {
    "/job-search": true,
    "/admin/job-search/job-by-office/499352": true,
    "/admin/prjobsearch/view": true
  },
  "jcarousel": {
    "ajaxPath": "/jcarousel/ajax/views",
    "carousels": {
      "jcarousel-dom-1": {
          "view_options": {
              "view_args": "",
              "view_path": "toppage",
              "view_base_path": null,
              "view_display_id": "block_2",
              "view_name": "ads",
              "jcarousel_dom_id": 1
          },
          "wrap": null,
          "responsive": 1,
          "auto": 5,
          "autoPause": 1,
          "start": 1,
          "skin": "default",
          "selector": ".jcarousel-dom-1",
          "vertical": false,
          "visible": null,
          "buttonNextHTML": "<a href=\"javascript:void(0)\"></a>",
          "buttonPrevHTML": "<a href=\"javascript:void(0)\"></a>"
      }
    }
  },
}
