$(function(){

  if($('.searchbox').length < 1) {
    return false
  }

  var $ttl = $('#views-save-form input#edit-title');
  //Views Save Title
  var sTTL = $('.page-header .sTTL').text();
  if(sTTL == "" || $('#is_view_save_search').val() == 'true'){
    sTTL = I18n.t("js.job_search.no_search_condition");
    $("#views-save-form #edit-submit").prop('disabled', true);
  } else {
    $ttl.on('keyup', function(){
      var $this = $(this);
      var val = $this.val();
      $("#views-save-form #edit-submit").prop('disabled', val === null || val.length === 0);
    });
  }
  truncate_title = (I18n.t("js.job_search.search_condition") + sTTL).substring(0, 255);
  $ttl.val(truncate_title);

  $('#edit-field-office-location-area-target-id-entityreference-filter, #edit-field-office-location-prefecture-target-id').on('change', function() {
    let areaCodeElement = $('#edit-field-office-location-area-target-id-entityreference-filter')
    let prefectureIdElement = $('#edit-field-office-location-prefecture-target-id')
    let current_target = this.id == 'edit-field-office-location-area-target-id-entityreference-filter' ? 'area' : 'prefecture'

    $.ajax({
      url: '/entityreference_filter',
      data: {
        area_code: areaCodeElement.val(),
        prefecture_id: prefectureIdElement.val(),
        current_target: current_target
      }
    }).done(function(res) {
      $('#edit-field-office-location-prefecture-target-id option').remove()
      prefectureIdElement.html('<option value="All">' + I18n.t("js.job_search.all") + '</option>')

      $.each(res.prefectures, function() {
        let option = document.createElement('option');
        option.value = this.attributes.id
        option.innerHTML = this.attributes.name
        prefectureIdElement.append(option)
      })
      prefectureIdElement.val(res.current_prefecture_id)

      let cityIdElement = $('#edit-field-office-location-city-target-id-entityreference-filter')
      $('#edit-field-office-location-city-target-id-entityreference-filter option').remove('option')

      let cityLabelElement = $('#edit-field-office-location-city-target-id-entityreference-filter-wrapper .optWrapper.multiple ul')
      $('#edit-field-office-location-city-target-id-entityreference-filter-wrapper .optWrapper.multiple ul').remove('li')

      $.each(res.cities, function() {
        let option = document.createElement('option');
        option.value = this.attributes.city_code
        option.innerHTML = this.attributes.city_name
        cityIdElement.append(option)

        let labelOption = document.createElement('option');
        labelOption.classList.add('opt')
        labelOption.innerHTML = '<span><i></i></span><label> ' + this.attributes.city_name + ' </label>'
        cityLabelElement.append(labelOption)
      })

      let selected_cities = []
      let cities_label = cityLabelElement.find('label')
      $('#selected_cities span').each(function() {
        selected_cities.push(this.dataset.cityId)
        let city_name = cityIdElement.find('option[value="' + this.dataset.cityId + '"]').html()
        cities_label.each(function() {
          if(this.innerHTML == city_name) this.parentNode.classList.add('selected')
        })
      })
      cityIdElement.val(selected_cities)
    });
  })

  setTimeout(function() {
    $('#edit-field-office-location-prefecture-target-id').trigger('change')
  }, 1)

  $('[data-toggle="open-searchbox"]').click(function(){
    $('[data-target="searchbox"]').stop(true, true).slideToggle();
  });

  if($("#contenttop").find(".searchbox").data('bytop') === false) {
    $("div.searchbox").css("display","none");
  };
});
