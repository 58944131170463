$(document).ready(function () {
  $(".js-select2").select2();

  $(".js-check-salary").each(function() {
    checkShowSalary(this);
  });

  $(".js-check-salary").on("change", function(){
    checkShowSalary(this)
  });
});

checkShowSalary = function (element) {
  var input = $(element);
  $(input.data("target")).toggle(input.is(":checked"));
}
