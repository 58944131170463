(function ($) {
  Drupal.behaviors.backtotop = {
    attach: function(context) {
      var exist= jQuery('#backtotop').length;
      if(exist == 0) {
        $("body", context).append("<button id='backtotop'>"+Drupal.t(Drupal.settings.back_to_top.back_to_top_button_text)+"</button>");
      }
      $(window).scroll(function() {
        if($(this).scrollTop() > Drupal.settings.back_to_top.back_to_top_button_trigger) {
          $('#backtotop').fadeIn();
        } else {
          $('#backtotop').stop(true).fadeOut();
        }
      });

      $('#backtotop', context).click(function() {
        $("html, body").bind("scroll mousedown DOMMouseScroll mousewheel keyup", function() {
          $('html, body').stop();
        });
        $('html,body').animate({ scrollTop: 0 }, 1200, 'swing', function() {
          $("html, body").unbind("scroll mousedown DOMMouseScroll mousewheel keyup");
        });
        return false;
      });
    }
  };
})(jQuery);
