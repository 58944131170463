
$(function() {
  $('[data-toggle="tab"]').on('click', function(){
    let tabs = $(this).parent();
    let panes = $(this).parent().next();
    let target = $(this).data('target');

    tabs.children('.active').removeClass('active');
    $(this).addClass('active');

    panes.children('.active').removeClass('active');
    panes.children('[data-pane="' + target + '"]').addClass('active');
  });
});
