$(document).ready(function() {
    //$('#edit-field-office-location-city-target-id-entityreference-filter-wrapper').css('display','block');
      $('#edit-field-office-location-city-target-id-entityreference-filter-wrapper .views-widget select[multiple]').css('display','block');

    // 監視フラグ*************************************
    var surveillance = false;
    //市区町村クリック するまで監視対象にしない
    $("#edit-field-office-location-prefecture-target-id-wrapper").on("change", function () {
         if($("#edit-field-office-location-prefecture-target-id").val() != "All" ){
             surveillance = true;
         }else if($("#edit-field-office-location-prefecture-target-id").val() == "All" ){
             surveillance = false;
             //$(".SumoSelect").css("display","none");
             $(".SumoSelect.open").removeClass('open');
             $(".CaptionCont.SelectBox").css("display","none");
          }
    });

    //エリアクリック 都道府県・市区町村リセット
    $("#edit-field-office-location-area-target-id-entityreference-filter").on("change", function () {
        //監視対象をリセット
        surveillance = false;
         $(".CaptionCont.SelectBox").css("display","none");
         $(".SumoSelect.open").removeClass('open');
         if($("#edit-field-office-location-area-target-id-entityreference-filter").val() == "All" ){
           $('#edit-field-office-location-prefecture-target-id-wrapper option').prop('selected', function() {
                  return this.defaultSelected;
           });
         }
    });


    // SumoSelect multi Select
    $("#edit-field-office-location-city-target-id-entityreference-filter").SumoSelect({
          placeholder: ' - 市区町村を選択する - ',
          nativeOnDevice: ['Android', 'BlackBerry', 'iPhone', 'iPad', 'iPod', 'Opera Mini', 'IEMobile', 'Silk'],
          forceCustomRendering: true,
          selectAll: false,
          csvDispCount:1,
          //captionFormat: ' - 市区町村を選択する - ',
          locale: ['OK', 'クリア', '全選択'],
    });
     cityViewCheck();



  // 対象ノードを選択
  var target = document.querySelector('#edit-field-office-location-city-target-id-entityreference-filter');
  if(!target){
    setTimeout(arguments.callee,1000);
    return false;
  }

//  // オブザーバインスタンスを作成
//  var observer = new MutationObserver(function(mutations) {
//    if(mutations.some( x => 
//         x.addedNodes 
//      //&& x.addedNodes instanceof NodeList 
//      && x.addedNodes.length > 0 
//      && x.type == 'childList'
//    )){
//      //監視後のタスク
//      doTask();
//    }
//  });


  // オブザーバインスタンスを作成
  var observer = new MutationObserver(callback);
  function callback(mutations) {
      if (mutations.some(function (x) {
             return x.addedNodes && x.addedNodes instanceof NodeList && x.addedNodes.length > 0 && x.type == 'childList';
          })
      ) {
        //監視後のタスク
        doTask();
      }
  }
  // 対象ノードとオブザーバの設定を渡す
  observer.observe(target, { childList: true, subtree: true });


   //監視後のタスク
   function doTask(){
      if(surveillance){
          cityViewCheck();
          $("#edit-field-office-location-city-target-id-entityreference-filter")[0].sumo.reload();
          surveillance = false;
      }
   }

   function cityViewCheck(){
      var val = $("#edit-field-office-location-prefecture-target-id").val();
      if(val == "All"){
         $(".CaptionCont.SelectBox").css("display","none");
      }else{
         $(".CaptionCont.SelectBox").css("display","block");
      }
   }
  


});
